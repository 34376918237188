import React, {useState} from 'react';
/** Calling GraphQL */
import { useStaticQuery, graphql } from "gatsby";
import { DefaultPage } from "../layout/DefaultPage";
import VideoPlayerComponent from '../components/VideoPlayer';
import Dialog from '../components/Dialog';
import VideoGallery from '../components/VideoGallery';

const Home = () => {
  /** Sinopsis handler*/
  const [sinopsis, setSinopsis] = useState(false);

  const showSinopsis = () => {
    setSinopsis(!sinopsis);
  }

  /** Getting landing data with staticQuery */
  const HOME_DATA = useStaticQuery(
    graphql`
      query {
        homeYaml {
          campaignSummary
          videoData {
            videoId
            videoTitle
          }
        }
      }
    `
  );
  /** Defragmenting data */
  const {videoData, campaignSummary} = HOME_DATA.homeYaml
  return(
    <DefaultPage pageTitle="Bienvenidos">
      <VideoGallery open={sinopsis}/>
      <Dialog sinopsis={sinopsis} showSinopsis={showSinopsis}  videoSummary={campaignSummary}/>
      <VideoPlayerComponent videoID={videoData.videoId} showSinopsis={showSinopsis}/>
    </DefaultPage>
  )
}

export default Home
